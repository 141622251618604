import React, { useContext } from 'react';
import img from '../../images/digital-transformation-picture.jpg';
import './DigitalTransformation.css';
import { GeneralContext } from '../../context/general-context/generalState';

const buttonCategory = 'Join Digital Transformation';
const buttonAction =
  'Join button on Digital Transformation Tab has been pressed';

const DigitalTransformation = () => {
  const { sendGAEvent } = useContext(GeneralContext);

  return (
    <div className="digital-transformation-main">
      <div
        className="digital-transformation-top-wrapper"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="2000"
      >
        <div className="digital-transformation-right-element">
          <h4>التحول الرقمي</h4>
          <p>
            تهانينا! لقد اخترت مسار التحول الرقمي
            <br />
            <br />
            يمكن أن يساهم التحول الرقمي للمؤسسات التعليمية في الحد من استهلاك
            الموارد عن طريق تقليل كمية الورق المستخدمة في طباعة المراسلات
            والاختبارات وتقييم التلاميذ وأيضا كتابة الواجبات والمهام، كما أن
            التواصل الالكتروني الفعال بين الإدارة والمعلمين أو أولياء الأمور
            يجعل إدارة المؤسسة التعليمية أكثر نظاماً ودقةً واحترافية
            <br />
            <br />
            ابدأ اليوم استخدام المهارات اللازمة لنقل مدرستك للعالم الرقمي
            كالبريد الالكتروني والتقويم والمهام والملاحظات وغيرها
          </p>
          <ol style={{ direction: 'rtl', textAlign: 'start' }}>
            <li>الاستخدام الفعال للبريد الالكتروني</li>
            <li>تنظيم المهام والملاحظات</li>
            <li>تنظيم المواعيد والزيارات</li>
            <li>تصميم موقع الكتروني للمدرسة</li>
            <li>إجراء الاختبارات الالكترونية</li>
            <li>جمع البيانات</li>
            <li>إجراء استطلاعات الرأي</li>
            <li>معرض العلوم الرقمي</li>
          </ol>
          <div className="digital-transformation-button-div">
            <a
              className="btn"
              style={{ backgroundColor: '#E64F30' }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSeLLzrSNTeFhVxzveel5Mlbe6oeObyNyGoTKvt22s5L0VDxmw/viewform"
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => sendGAEvent(buttonCategory, buttonAction)}
            >
              للانضمام
              <i className="material-icons left">assignment_return</i>
            </a>
          </div>
        </div>
        <div className="digital-transformation-left-element">
          <img src={img} alt="" width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
};

export default DigitalTransformation;
