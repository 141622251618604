import React, { useEffect } from 'react';
import AOS from 'aos';
import M from 'materialize-css/dist/js/materialize.min.js';
import ReactGA from 'react-ga';
import Analytics from 'react-router-ga';

import './App.css';
import 'aos/dist/aos.css';
import 'materialize-css/dist/css/materialize.min.css';

import { GeneralProvider } from './context/general-context/generalState';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Layout from './layout/Layout';
import Home from './pages/Home/Home';
import OnlineEducation from './pages/Online-Education/OnlineEducation';
import DigitalTransformation from './pages/Digital-Transformation/DigitalTransformation';
import Training from './pages/Training/Training';
import ContactUs from './pages/Contact-Us/ContactUs';

const googleAnalyticsId = 'UA-187471565-1';

const App = () => {
  useEffect(() => {
    AOS.init();
    M.AutoInit();
    ReactGA.initialize(googleAnalyticsId);
  }, []);

  return (
    <Router>
      <Analytics id={googleAnalyticsId}>
        <GeneralProvider>
          <Switch>
            <Layout>
              <Route exact path="/" component={Home} />
              <Route path="/online-education" component={OnlineEducation} />
              <Route
                path="/digital-transformation"
                component={DigitalTransformation}
              />
              <Route path="/training" component={Training} />
              <Route path="/contact-us" component={ContactUs} />
            </Layout>
          </Switch>
        </GeneralProvider>
      </Analytics>
    </Router>
  );
};

export default App;
