import React from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import './OnlineEducationModal.css';

const OnlineEducationModal = () => {
  const handleClick = () => {
    M.toast({
      html: '<strong>شكراً لكم، سيتم التواصل معكم في أقرب فرصة</strong>',
      classes: 'rounded',
    });
  };
  return (
    <div id="online-education-modal" className="modal">
      <div className="modal-content" style={{ direction: 'rtl' }}>
        <h4>بيانات شخصية</h4>
        <div className="online-education-modal-body">
          <div className="row">
            <div className="input-field col s5">
              <select defaultValue="">
                <option value="" disabled></option>
                <option value="1">معلم</option>
                <option value="2">مدير مدرسة</option>
                <option value="3">ولي أمر</option>
                <option value="4">غير ذلك</option>
              </select>
              <label>اختيار الصفة</label>
            </div>
            <div className="input-field col push-s2 s5">
              <input id="first_name" type="text" className="validate" />
              <label htmlFor="first_name">الاسم</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col push-s3 s6">
              <input id="email" type="email" className="validate" />
              <label htmlFor="email">البريد الإلكتروني</label>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <p>ستصلك الموافقة على طلبك والمشعارف شن هي على بريدك الإلكتروني</p>
        <p>
          يمكنك مراستنا على البريد الالكتروني في حالة
          <em> عدم وجود طلبك</em>
        </p>
      </div>
      <hr />
      <div className="modal-footer row">
        <div className="col push-s4 s4">
          <button
            className="btn modal-close blue lighten-3"
            style={{ width: '100%' }}
            onClick={handleClick}
          >
            ارسال
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnlineEducationModal;
