import React, { Fragment } from 'react';
import logo from '../images/logo.png';

const Logobar = () => {
  return (
    <Fragment>
      <div style={{ height: '7rem' }}>
        <nav style={{ backgroundColor: '#f2f2f2', height: '7rem' }}>
          <div className="nav-wrapper">
            <a
              href="#!"
              className="brand-logo right"
              style={{ margin: '17px 25px' }}
            >
              <img src={logo} alt="doroos logo" height="70vh" />
            </a>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default Logobar;
