import React, { useContext } from 'react';
import { GeneralContext } from '../context/general-context/generalState';
import { Link, useLocation } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import './Navbar.css';

const Navbar = () => {
  const pathname = useLocation().pathname;
  const { layoutStyle } = useContext(GeneralContext);

  const mobile = document.querySelector('#mobile-demo');
  const mobileOverLay = document.querySelector('.sidenav-overlay');
  const showSideBar = () => {
    mobile.style.transform = 'translateX(0%)';
    mobile.style.transitionDuration = '1s';
    mobileOverLay.style.display = 'block';
    mobileOverLay.style.opacity = 1;
    document.body.style.overflow = 'hidden';
  };

  const hideSideBar = () => {
    mobile.style.transform = 'translateX(-105%)';
    mobileOverLay.style.display = 'none';
    mobileOverLay.style.opacity = 0;
    document.body.style.overflow = '';
  };

  return (
    <>
      <div className="nav-main">
        <ScrollToTop />
        <nav className={layoutStyle.navBar}>
          <div className="nav-wrapper">
            <a
              href="/"
              data-target="mobile-demo"
              className="sidenav-trigger"
              onClick={showSideBar}
            >
              <i className="material-icons">menu</i>
            </a>

            <ul className="left hide-on-med-and-down nav-txt">
              <li className={pathname === '/contact-us' ? 'active' : ''}>
                <Link to="/contact-us">اتصل بنا</Link>
              </li>
              <li className={pathname === '/training' ? 'active' : ''}>
                <Link to="/training">التدريب</Link>
              </li>
              <li
                className={
                  pathname === '/digital-transformation' ? 'active' : ''
                }
              >
                <Link to="/digital-transformation">التحول الرقمي</Link>
              </li>

              <li className={pathname === '/online-education' ? 'active' : ''}>
                <Link to="/online-education">التعليم عن بُعد</Link>
              </li>
              <li className={pathname === '/' ? 'active' : ''}>
                <Link to="/">الرئيسية</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <ul className="sidenav" id="mobile-demo">
        <li className="right-align">
          <Link to="/" onClick={hideSideBar}>
            الرئيسية
          </Link>
        </li>
        <li className="right-align">
          <Link to="/online-education" onClick={hideSideBar}>
            التعليم عن بُعد
          </Link>
        </li>
        <li className="right-align">
          <Link to="/digital-transformation" onClick={hideSideBar}>
            التحول الرقمي
          </Link>
        </li>
        <li className="right-align">
          <Link to="/training" onClick={hideSideBar}>
            التدريب
          </Link>
        </li>
        <li className="right-align">
          <Link to="/contact-us" onClick={hideSideBar}>
            اتصل بنا
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Navbar;
