import React, { useState, useEffect } from 'react';
import './Carousel.css';
// import AltImage from '../../images/carousel-placeholder.png'

let interval;
const slides = document.getElementsByClassName('mySlides');

const Carousel = () => {
  const [index, setIndex] = useState(1);
  // NEXT SLIDE
  const nextSlide = () => {
    clearInterval(interval);
    setIndex((prevState) => {
      if (prevState < slides.length) {
        return prevState + 1;
      } else {
        return 1;
      }
    });
  };

  // PREVIOUS SLIDE
  const prevSlide = () => {
    clearInterval(interval);
    setIndex((prevState) => {
      if (prevState > 1) {
        return prevState - 1;
      } else {
        return slides.length;
      }
    });
  };
  // const goToSlide = (n) => {
  //   clearInterval(interval);
  //   setIndex(n);
  // };

  // AUTO FORWARD SLIDES
  useEffect(() => {
    slides[index - 1].style.display = 'block';
    interval = setInterval(() => {
      setIndex((prevState) => {
        if (prevState < slides.length) {
          return prevState + 1;
        } else {
          return 1;
        }
      });
    }, 3000);
    return () => {
      clearInterval(interval);
      slides[index - 1] &&
        slides[index - 1].style &&
        (slides[index - 1].style.display = 'none');
    };
  }, [index]);

  return (
    <>
      <div className="slideshow-container">
        <div className="mySlides fade">
          {/* <img src={carousel[0]} style={{ width: '100%' }} alt="aaa" /> */}
          <img className="carousel-image-1" alt="" width="1240" height="600" />
          <div className="text">
            <h4>جلسات تدريبية وورش عمل</h4>
            {/* <h6>التعاون مع المركز العام للتدريب وتطوير التعليم</h6> */}
          </div>
        </div>
        <div className="mySlides fade">
          {/* <img src={carousel[1]} style={{ width: '100%' }} alt="" /> */}
          <img className="carousel-image-2" alt="" width="1240" height="600" />
          <div className="text">
            <h4>حلول التعليم عن بُعد</h4>
            <h6>إنشاء ودعم الفصول الافتراضية للمدارس</h6>
          </div>
        </div>
        <div className="mySlides fade">
          {/* <img src={carousel[2]} style={{ width: '100%' }} alt="" /> */}
          <img className="carousel-image-3" alt="" width="1240" height="600" />
          <div className="text">
            <h4>التحول الرقمي للمؤسسات التعليمية</h4>
            <h6>مفاهيم معاصرة أفكار ابداعية معايير عالمية</h6>
          </div>
        </div>

        {/* Next & Previous Manual Change */}
        <button className="prev" onClick={prevSlide}>
          &#10094;
        </button>
        <button className="next" onClick={nextSlide}>
          &#10095;
        </button>
      </div>

      {/* <!-- The dots/circles --> */}
      {/* <div style={{ textAlign: 'center' }}>
        <span className="dot" onClick={() => goToSlide(1)}></span>
        <span className="dot" onClick={() => goToSlide(2)}></span>
        <span className="dot" onClick={() => goToSlide(3)}></span>
      </div> */}
    </>
  );
};

export default Carousel;
