const cardContent = [
  {
    title: 'التعليم عن بُعد',
    body: 'مستقبل التعليم وأسلوب جديد للحياة المعاصرة',
    button: 'تعرف على المزيد',
    link: '/online-education',
    backgroundImage: 'card-1-background',
    fade: 'fade-up-left',
  },
  {
    title: 'التحول الرقمي',
    body:
      'المدارس المميزة تستخدم التكنولوجيا بطرق هادفة وذات مغزى لتصبح مكاناً مشوقاُ وأكثر ترحيبا',
    button: 'تعلم المزيد',
    link: '/digital-transformation',
    backgroundImage: 'card-2-background',
    fade: 'fade-up',
  },
  {
    title: 'التدريب',
    body:
      'المعلم المبدع لا يتوقف عن التعلم، شارك طلابك في العملية التعليمية وكن قدوتهم الحقيقية',
    button: 'للانضمام',
    link: '/training',
    backgroundImage: 'card-3-background',
    fade: 'fade-up-right',
  },
];

export default cardContent;
