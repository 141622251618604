import React, { useContext } from 'react';
import img from '../../images/online-education-picture.jpg';
import './OnlineEducation.css';
import { GeneralContext } from '../../context/general-context/generalState';

const buttonCategory = 'Join Online Education';
const buttonAction = 'Join button on Online Education Tab has been pressed';

const OnlineEducation = () => {
  const { sendGAEvent } = useContext(GeneralContext);

  return (
    <div className="online-education-main">
      <div
        className="online-education-top-wrapper"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="2000"
      >
        <div className="online-education-right-element">
          <h4>التعليم عن بعد</h4>
          <p>
            لن تتوقف الدراسة بعد اليوم
            <br />
            <br />
            التعليم عن بُعد هو باختصار مستقبل التعليم، نحن نرشدك لاكتشاف هذا
            العالم ونُقدم الدعم اللازم لك ولمؤسستك التعليمية والمعلمين والطلبة
            وأولياء الأمور، نمنحك منظور جديد وأفكار إبداعية لتتميز من خلال حلول
            تكنولوجية كاملة لاستمرار التعليم دون التأثر بالظروف والأحداث. شارك
            الآن وابدأ فصلك الدراسي من منصة جوجل التعليمية بالإضافة إلى الدعم
            والتدريب
          </p>
          <div className="online-education-button-div">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfr81cQI0qGOBjqdKIkNhDMMeFhYU72XSHwzE1p4apcrGdOxg/viewform"
              rel="noopener noreferrer"
              target="_blank"
              className="btn"
              onClick={() => sendGAEvent(buttonCategory, buttonAction)}
              style={{ backgroundColor: '#30B0E5' }}
            >
              للانضمام
            </a>
          </div>
        </div>
        <div className="online-education-left-element">
          <img src={img} alt="" width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
};

export default OnlineEducation;
