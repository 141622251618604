import React from 'react';
import Logobar from './Logobar';
import Navbar from './Navbar';
import Footer from './Footer';
import OnlineEducationModal from '../components/Modals/OnlineEducationModal';

const Layout = ({ children }) => {
  return (
    <>
      <Logobar />
      <Navbar />
      <OnlineEducationModal />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
