import React, { useContext } from 'react';
import img from '../../images/training-picture.jpg';
import './Training.css';
import { GeneralContext } from '../../context/general-context/generalState';

const buttonCategory = 'Join Training';
const buttonAction = 'Join button on Training Tab has been pressed';

const Training = () => {
  const { sendGAEvent } = useContext(GeneralContext);
  return (
    <div className="training-main">
      <div
        className="training-top-wrapper"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="2000"
      >
        <div className="training-right-element">
          <h4>التدريب</h4>
          <p>
            لا يكفي فقط أن تتوفر التقنية على شكل أجهزة وأدوات، من المهم أيضا رفع
            كفاءة المعلمين والمتدربين وتوظيف هذه الأدوات لضمان نتيجة تعليمية
            فعالة للطلاب، لرفع أداء وكفاءة المعلمين والمتدربين يجب الانضمام
            لبرامج تدريبية ذات معايير عالمية ويقدمها مدرب محترف ومعتمد، نقدم لكم
            تدريب شامل لحزمة برامج جوجل التعليمية وأدوات وأساسيات التعليم عن
            بُعد
            <br />
            <br />
            امتلك مهارات المعلم المبدع وابدأ تطوير ذاتك وتعزيز مهاراتك التقنية!
          </p>
          <ol style={{ direction: 'rtl', textAlign: 'start' }}>
            <li>إنشاء وإدارة الفصل الالكتروني</li>
            <li>إنشاء نموذج اختبار الكتروني</li>
            <li>الاستخدام الفعال للبريد الالكتروني</li>
            <li>صياغة المحتوى الرقمي حسب الأهداف التعليمية</li>
            <li>استخدام برامج الاتصال والاجتماعات</li>
            <li>تنظيم المهام والملاحظات والتقويم</li>
            <li>مهارات البحث واستخدام الانترنت</li>
            <li>
              المستندات، جداول البيانات، الشرائح التقديمية،المواقع الالكترونية
              والرسومات التوضيحية
            </li>
          </ol>
          <div className="training-button-div">
            <a
              className="btn"
              style={{ backgroundColor: '#F9A134' }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSdkzUoAw1SHPCbZWxjMOvxZKCaNf3Sn358PWZ6pHbqcGUUJuA/viewform"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => sendGAEvent(buttonCategory, buttonAction)}
            >
              للانضمام
              <i className="material-icons left">assignment_return</i>
            </a>
          </div>
        </div>
        <div className="training-left-element">
          <img src={img} alt="" width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
};

export default Training;
