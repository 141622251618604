import React from 'react';
import { Link } from 'react-router-dom';
import './CardItem.css';

const CardItem = ({ content }) => {
  return (
    <div
      className="row"
      data-aos={content.fade}
      data-aos-offset="400"
      data-aos-duration="1250"
    >
      <div className="col s10 m12 offset-s1">
        <div className={`card ${content.backgroundImage}`}>
          <div className="card-content white-text right-align">
            <div id="card-content-text">
              <span className="card-title">{content.title}</span>
              <p>{content.body}</p>
            </div>
            <div className="card-action center-align">
              <Link
                className="waves-effect waves-light black-text btn-large blue lighten-5"
                to={content.link}
              >
                {content.button}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
