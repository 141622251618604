import React, { useContext } from 'react';
import img from '../../images/contact-us-picture.jpg';
import './ContactUs.css';
import { GeneralContext } from '../../context/general-context/generalState';

const buttonCategory = 'Contact Us Email';
const buttonAction = 'Contact Us Email on Contact Us Tab has been pressed';

const ContactUs = () => {
  const { sendGAEvent } = useContext(GeneralContext);
  return (
    <div className="contact-us-main">
      <div
        className="contact-us-top-wrapper"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="2000"
      >
        <div className="contact-us-right-element">
          <h4>اتصل بنا</h4>

          <h2>لديك سؤال أو طلب؟</h2>
          <p>
            دروس لحلول التعليم عن بُعد والتحول الرقمي للمؤسسات التعليمية، يسرنا
            تقديم المساعدة
            <br />
            <br />
            !امتلك مهارات المعلم المبدع وابدأ تطوير ذاتك وتعزيز مهاراتك التقنية
          </p>
          <br />
          <br />
          <p>
            <a
              href="mailto:info@doroos.ly"
              className="contact-us-email"
              onClick={() => sendGAEvent(buttonCategory, buttonAction)}
            >
              info@doroos.ly
            </a>
            :يمكنك التواصل عبر البريد الالكتروني
          </p>
        </div>
        <div className="contact-us-left-element">
          <img src={img} alt="" width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
