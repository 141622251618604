import React from 'react';
import CardItem from './CardItem';
import cardContent from './cardContents';
import './Cards.css';

const Cards = () => {
  return (
    <>
      <div
        className="row cards-group"
        // data-aos="fade-right"
        // data-aos-offset="200"
        // data-aos-duration="1000"
      >
        <h3 className="center-align">مسارات دروس تبدأ هنا</h3>
        <h6 className="center-align">
          اختر ما يناسبك من الحلول التعليمية التكنولوجية وانضم للعالم الرقمي
        </h6>
        <div className="card-container">
          {cardContent.map((card, i) => (
            <div className="card-item" key={i}>
              <CardItem content={card} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Cards;
