import { SET_LAYOUT_STYLE } from './Constants';

const generalReducer = (state, action) => {
  switch (action.type) {
    case SET_LAYOUT_STYLE:
      return { ...state, layoutStyle: action.payload };

    default:
      return state;
  }
};

export default generalReducer;
