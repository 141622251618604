import React, { createContext, useReducer, useEffect } from 'react';
import generalReducer from './generalReducer';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const initialState = {
  layoutStyle: {
    navBar: '',
    footerTopBar: '',
    footerBottomBar: '',
  },
};

export const GeneralContext = createContext(initialState);

export const GeneralProvider = ({ children }) => {
  const location = useLocation();

  const [state, dispatch] = useReducer(generalReducer, initialState);

  const sendGAEvent = (category, action) => {
    ReactGA.event({ category, action });
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        return dispatch({
          type: 'SET_LAYOUT_STYLE',
          payload: {
            navBar: 'home-navbar',
            footerTopBar: 'home-footer-top-bar',
            footerBottomBar: 'home-footer-bottom-bar',
          },
        });
      case '/online-education':
        return dispatch({
          type: 'SET_LAYOUT_STYLE',
          payload: {
            navBar: 'home-navbar',
            footerTopBar: 'home-footer-top-bar',
            footerBottomBar: 'home-footer-bottom-bar',
          },
        });
      case '/digital-transformation':
        return dispatch({
          type: 'SET_LAYOUT_STYLE',
          payload: {
            navBar: 'digital-transformation-navbar',
            footerTopBar: 'digital-transformation-footer-top-bar',
            footerBottomBar: 'digital-transformation-footer-bottom-bar',
          },
        });
      case '/training':
        return dispatch({
          type: 'SET_LAYOUT_STYLE',
          payload: {
            navBar: 'training-navbar',
            footerTopBar: 'training-footer-top-bar',
            footerBottomBar: 'training-footer-bottom-bar',
          },
        });

      default:
        return dispatch({
          type: 'SET_LAYOUT_STYLE',
          payload: {
            navBar: 'home-navbar',
            footerTopBar: 'home-footer-top-bar',
            footerBottomBar: 'home-footer-bottom-bar',
          },
        });
    }
  }, [location.pathname]);

  return (
    <GeneralContext.Provider
      value={{
        layoutStyle: state.layoutStyle,
        sendGAEvent,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
