import React, { useContext } from 'react';
import './Footer.css';
import { GeneralContext } from '../context/general-context/generalState';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { layoutStyle } = useContext(GeneralContext);
  return (
    <footer className={`page-footer ${layoutStyle.footerTopBar}`}>
      <div className="container">
        <div className="row ">
          <div className="col l4 offset-l2 s12 right-align">
            {/* <ul>
              <li>
                <a
                  className="grey-text text-lighten-3"
                  href="https://dorooslibya.blogspot.com/?zx=fa726fbd664d715a"
                >
                  المدونة
                </a>
              </li>
              <li>
                <a className="grey-text text-lighten-3" href="#!">
                  قناة دروس
                </a>
              </li>
              <li>
                <a className="grey-text text-lighten-3" href="#!">
                  صفحة دروس
                </a>
              </li>
            </ul> */}
          </div>
          <div className="col l4 offset-l2 s12 right-align">
            <ul>
              <li>
                <button className="grey-text text-lighten-3">
                  <Link to="/" className="page-footer-color">
                    الرئيسية
                  </Link>
                </button>
              </li>
              {/* <li>
                <a className="grey-text text-lighten-3" href="#!">
                  من نحن ؟
                </a>
              </li> */}
              <li>
                <button className="grey-text text-lighten-3">
                  <Link to="/contact-us" className="page-footer-color">
                    اتصل بنا
                  </Link>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`footer-copyright ${layoutStyle.footerBottomBar} right-align`}
      >
        <div className="container">
          Doroos.ly حقوق النشر محفوظة © {new Date().getFullYear()}
          {/* <a className="grey-text text-lighten-4 right" href="#!">
            More Links
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
